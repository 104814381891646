/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/rating';
import $ from 'jquery';
import 'slick-carousel';

import 'sylius/ui/app';
import 'sylius/ui/sylius-api-login';
import 'sylius/ui/sylius-api-toggle';

import './sylius-add-to-cart';
import './sylius-address-book';
import './sylius-province-field';
import './sylius-variant-images';
import './sylius-variants-prices';

$(document).ready(() => {
  $('.popup-js').popup();

  $('.cart.button').popup({
    popup: $('.cart.popup'),
    on: 'click',
    onUnplaceable() {
      window.location.href = $('#sylius-go-to-cart').attr('href');
    },
    silent: true,
  });

  $('.star.rating').rating({
    fireOnInit: true,
    onRate(value) {
      $('[name="sylius_product_review[rating]"]:checked').removeAttr('checked');
      $(`#sylius_product_review_rating_${value - 1}`).attr('checked', 'checked');
    },
  });

  $('#sylius_checkout_address_customer_email').apiToggle({
    dataType: 'json',
    method: 'GET',
    throttle: 1500,

    beforeSend(settings) {
      const email = $('#sylius_checkout_address_customer_email').val();

      if (email.length < 3) {
        return false;
      }

      /* eslint-disable-next-line no-param-reassign */
      settings.data = {
        email,
      };

      return settings;
    },

    successTest(response) {
      return $('#sylius_checkout_address_customer_email').val() === response.username;
    },
  }, $('#sylius-api-login-form'));

  $('#sylius-api-login').apiLogin({
    method: 'POST',
    throttle: 500,
  });

  $('#sylius-product-adding-to-cart').addToCart();

  $('#sylius-shipping-address').addressBook();
  $('#sylius-billing-address').addressBook();
  $(document).provinceField();
  $(document).variantPrices();
  $(document).variantImages();

  $('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

  // $('.carousel').slick({
  //   infinite: true,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   prevArrow: $('.carousel-left'),
  //   nextArrow: $('.carousel-right'),
  //   appendArrows: false,
  // });

  // ==============
  //  TOP CAROUSEL
  // ==============

  // $('.carousel-hero-wrapper').slick({
  //   centerMode: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   prevArrow: $('.cb__button--prev'),
  //   nextArrow: $('.cb__button--next'),
  //   variableWidth: true,
  // });
  //
  // $(window).on('resize', function() {
  //   var newWindow = $(window).width();
  //   $('.carousel-hero-wrapper').css('width', newWindow);
  // });

  // ==============================
  //  TOAXON PAGE CAROUSEL
  // ==============================

  // var dim = $('.lay__column').width();
  //
  // $('.carousel-hero-wrapper-taxon').slick({
  //   infinite: false,
  //   slidesToShow: 1.1,
  //   prevArrow: $('.cb__button--prev_taxon'),
  //   nextArrow: $('.cb__button--next_taxon'),
  // }).css('width', dim);

  // ==============================
  //  RECOMMENDED PRODUCTS CAROUSEL
  // ==============================

  // $('.recently_carousel').slick({
  //   infinite: false,
  //   slidesToShow: 4.2,
  //   slidesToScroll: 1,
  //   prevArrow: $('.lay__carousel__nav-button-left'),
  //   nextArrow: $('.lay__carousel__nav-button-right'),
  //   appendArrows: false
  // }).css('width', dim);
  //
  // $('.slick-track').css('min-width', dim);
  //
  // $('.recommended__carousel__wrapper').slick({
  //   infinite: false,
  //   slidesToShow: 4.2,
  //   slidesToScroll: 1,
  //   prevArrow: $('.recommended__carousel__nav-button-left'),
  //   nextArrow: $('.recommended__carousel__nav-button-right'),
  //   appendArrows: false,
  // }).css('width', dim);

  // ==============================
  //  KONTO BOX
  // ==============================

  $("[cb-acc-element=open]").click(function() {
    $(".konto-box").addClass("inactive")
    $(this).parents(".konto-box").addClass("active")
    $(this).parents(".konto-box").find("input").eq(0).focus()

    const el = document.querySelectorAll('[id^="sylius_user_change_password"]');

    if (typeof el !== 'undefined') {
      /**
       * Current Password field
       */
      const passwordFieldsCurrentPassword = $("#sylius_user_change_password_currentPassword");
      const togglePasswordIconCurrentPassword = $("#togglePasswordIcon1");
      const togglePasswordVisibleIconCurrentPassword = $("#togglePasswordVisibleIcon1");

      if ((passwordFieldsCurrentPassword.length > 0) && (passwordFieldsCurrentPassword.length > 0) && (passwordFieldsCurrentPassword.length > 0)) {
        if (typeof togglePasswordVisibleIconCurrentPassword !== "undefined") {
          if (passwordFieldsCurrentPassword.data('visible') && (passwordFieldsCurrentPassword.data('visible') === 'true')) {
            togglePasswordIconCurrentPassword.css("display", "none");
          } else {
            togglePasswordVisibleIconCurrentPassword.css("display", "none");
          }

          togglePasswordIconCurrentPassword.on('click', function () {
            togglePassChangePass(passwordFieldsCurrentPassword, togglePasswordIconCurrentPassword, togglePasswordVisibleIconCurrentPassword);
          });

          togglePasswordVisibleIconCurrentPassword.on('click', function () {
            togglePassChangePass(passwordFieldsCurrentPassword, togglePasswordIconCurrentPassword, togglePasswordVisibleIconCurrentPassword);
          });
        }
      }

      /**
       * Password NewPassword First
       */
      const passwordFieldsNewPasswordFirst = $("#sylius_user_change_password_newPassword_first");
      const togglePasswordIconNewPasswordFirst = $("#togglePasswordIcon2");
      const togglePasswordVisibleIconNewPasswordFirst = $("#togglePasswordVisibleIcon2");

      if ((passwordFieldsNewPasswordFirst.length > 0) && (passwordFieldsNewPasswordFirst.length > 0) && (passwordFieldsNewPasswordFirst.length > 0)) {
        if (typeof togglePasswordVisibleIconNewPasswordFirst !== "undefined") {
          if (passwordFieldsNewPasswordFirst.data('visible') && (passwordFieldsNewPasswordFirst.data('visible') === 'true')) {
            togglePasswordIconNewPasswordFirst.css("display", "none");
          } else {
            togglePasswordVisibleIconNewPasswordFirst.css("display", "none");
          }

          togglePasswordIconNewPasswordFirst.on('click', function () {
            togglePassChangePass(passwordFieldsNewPasswordFirst, togglePasswordIconNewPasswordFirst, togglePasswordVisibleIconNewPasswordFirst);
          });

          togglePasswordVisibleIconNewPasswordFirst.on('click', function () {
            togglePassChangePass(passwordFieldsNewPasswordFirst, togglePasswordIconNewPasswordFirst, togglePasswordVisibleIconNewPasswordFirst);
          });
        }
      }

      /**
       * Password NewPassword Second
       */
      const passwordFieldsNewPasswordSecond = $("#sylius_user_change_password_newPassword_second");
      const togglePasswordIconNewPasswordSecond = $("#togglePasswordIcon3");
      const togglePasswordVisibleIconNewPasswordSecond = $("#togglePasswordVisibleIcon3");

      if ((passwordFieldsNewPasswordSecond.length > 0) && (passwordFieldsNewPasswordSecond.length > 0) && (passwordFieldsNewPasswordSecond.length > 0)) {
        if (typeof togglePasswordVisibleIconNewPasswordSecond !== "undefined") {
          if (passwordFieldsNewPasswordSecond.data('visible') && (passwordFieldsNewPasswordSecond.data('visible') === 'true')) {
            togglePasswordIconNewPasswordSecond.css("display", "none");
          } else {
            togglePasswordVisibleIconNewPasswordSecond.css("display", "none");
          }

          togglePasswordIconNewPasswordSecond.on('click', function () {
            togglePassChangePass(passwordFieldsNewPasswordSecond, togglePasswordIconNewPasswordSecond, togglePasswordVisibleIconNewPasswordSecond);
          });

          togglePasswordVisibleIconNewPasswordSecond.on('click', function () {
            togglePassChangePass(passwordFieldsNewPasswordSecond, togglePasswordIconNewPasswordSecond, togglePasswordVisibleIconNewPasswordSecond);
          });
        }
      }
    }
  })

  function togglePassChangePass(passwordField, togglePasswordIcon, togglePasswordVisibleIcon) {
    const isPasswordVisible = passwordField.data('visible') === 'true';

    if (isPasswordVisible) {

      passwordField.attr("type", 'password');
      passwordField.data("visible", 'false');
      togglePasswordIcon.css("display", 'inline');
      togglePasswordVisibleIcon.css("display", 'none');
    } else {
      passwordField.attr("type", 'text');
      passwordField.data("visible", 'true');
      togglePasswordIcon.css("display", 'none');
      togglePasswordVisibleIcon.css("display", 'inline');
    }
  }


  $("[cb-acc-element=close]").click(function() {
    $(".konto-box").removeClass("active")
    $(".konto-box").removeClass("inactive")
    $(".konto-box").find("input").blur()

  });

  // ==================
  //  HIDDEN PASSWORD
  // ==================

  const passwordFields = document.querySelectorAll('input[type="password"]');
  const togglePasswordIcons = document.querySelectorAll('[id^="togglePasswordIcon"]');
  const togglePasswordVisibleIcons = document.querySelectorAll('[id^="togglePasswordVisibleIcon"]');

  function togglePass(passwordField, togglePasswordIcon, togglePasswordVisibleIcon) {
    const isPasswordVisible = passwordField.getAttribute('data-visible') === 'true';

    if (isPasswordVisible) {

      passwordField.type = 'password';
      passwordField.setAttribute('data-visible', 'false');
      togglePasswordIcon.style.display = 'inline';
      togglePasswordVisibleIcon.style.display = 'none';
    } else {
      passwordField.type = 'text';
      passwordField.setAttribute('data-visible', 'true');
      togglePasswordIcon.style.display = 'none';
      togglePasswordVisibleIcon.style.display = 'inline';
    }
  }

  if ((passwordFields.length > 0) && (togglePasswordIcons.length > 0) && (togglePasswordVisibleIcons.length > 0)) {
    for (let i = 0; i < passwordFields.length; i++) {
      const passwordField = passwordFields[i];
      const togglePasswordIcon = togglePasswordIcons[i];
      const togglePasswordVisibleIcon = togglePasswordVisibleIcons[i];

      if (typeof togglePasswordVisibleIcon !== "undefined") {
        if (passwordField.getAttribute('data-visible') === 'true') {
          togglePasswordIcon.style.display = 'none';
        } else {
          togglePasswordVisibleIcon.style.display = 'none';
        }

        togglePasswordIcon.addEventListener('click', function () {
          togglePass(passwordField, togglePasswordIcon, togglePasswordVisibleIcon);
        });

        togglePasswordVisibleIcon.addEventListener('click', function () {
          togglePass(passwordField, togglePasswordIcon, togglePasswordVisibleIcon);
        });
      }
    }
  }

  const error_fields = document.querySelectorAll('[class="form__helper-text"]');

  for (let i = 0; i < error_fields.length; i++) {
    const error_field = error_fields[i];
    if (error_field.innerHTML !== "") {
      error_field.parentElement.classList.add('cb-invalid');
    }
  }
});
